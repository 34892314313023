import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import ContactForm from '../components/ContactForm';

const IndexPage = () => (
  <Layout fullMenu subPage>
    <Seo
      description=''
      keywords=''
      title='Kontakt'
    />
    <article id='main'>
      <header id='main-photo' className='sub-page'>
        <StaticImage
          src='../assets/images/kontakt1.jpg' alt='Kačka'
          placeholder='blurred'
          objectFit='cover'
          className='static-image'
          objectPosition='50% 0%'
        />
        <div className='header-text'>
          <h1>Kontakt</h1>
          <p>
            Zavolejte nebo napište, ráda si s vámi popovídám o tom co bych pro vás mohla
            udělat.
          </p>
        </div>
      </header>
      <section className='wrapper style5 contact'>
        <div className="inner">
          <ContactForm />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
