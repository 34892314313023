import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function ContactForm() {
  const { register, handleSubmit, reset } = useForm();
  const [success, setSuccess] = useState(false);
  const onSubmit = (data) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    }).then(() => {
        reset();
        setSuccess(true);
      },
    ).catch(error => alert(error));
  };
  // TODO validations

  if (success) {
    return <h3>Zpráva byla odeslána. Brzy se vám ozvu. Díky.</h3>;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: '<form name="contact" netlify netlify-honeypot="bot-field" hidden><input type="text" name="name" /><input type="text" name="phone" /><input type="email" name="email" /><textarea name="message"></textarea></form>' }} />
      <form onSubmit={handleSubmit(onSubmit)} name='contact'>

        <input type='text' placeholder='Jméno' {...register('name', { required: false, maxLength: 80 })} />
        <input type='text' placeholder='Telefon' {...register('phone', { required: false, maxLength: 100 })} />
        <input type='text' placeholder='E-mail' {...register('email', {
          required: false,
          //pattern: /^\S+@\S+$/i
        })} />
        <textarea placeholder='Zpráva' {...register('message', {})} />
        <br />
        <input type='submit' />
      </form>
    </>
  );
}